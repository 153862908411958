import { useCallback } from 'react';
import { disableRefetchRetry } from '@noah-labs/fe-shared-data-access-shared';
import type {
  TpFeFeature,
  TpFeFeatureFlag,
  TpUserSettingsConsentQuery,
} from '@noah-labs/fe-shared-data-access-user';
// eslint-disable-next-line no-restricted-imports
import { getUIFeatures, useUserInitQuery } from '@noah-labs/fe-shared-data-access-user';
import {
  fiatCurrencyFromCode,
  getUserFullDisplayName,
  type TpFiatCurrencyUI,
} from '@noah-labs/fe-shared-ui-shared';
import type { UserProfile } from '@noah-labs/shared-schema-gql';
import { UserGroup } from '@noah-labs/shared-schema-gql';
import { getUserInitial } from '@noah-labs/shared-util-vanilla';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { getUserLnAddress } from '../utils/getUserLnAddress';

const userInitQueryKey = useUserInitQuery.getKey();

type TpUserProfileUi = UserProfile & {
  features: Map<TpFeFeature, TpFeFeatureFlag>;
  fiatCurrency: TpFiatCurrencyUI;
  fiatPaymentCurrency: TpFiatCurrencyUI;
  fullDisplayName: string | undefined;
  isNoahUser: boolean | undefined;
  lnAddress: string | undefined;
  userInitial: string;
};

export type TpUseUserInitUiQueryResults = UseQueryResult<{
  userProfile: TpUserProfileUi;
  userSettingsConsent: TpUserSettingsConsentQuery;
}> & { invalidate: () => Promise<void> };

export function useUserInitUi(): TpUseUserInitUiQueryResults {
  const userInitResponse = useUserInitQuery(undefined, {
    ...disableRefetchRetry,
    select: (data) => ({
      ...data,
      userProfile: {
        ...data.userProfile,
        features: getUIFeatures({
          FeatureFlags: data.userProfile.FeatureFlags,
          KycVerification: data.userProfile.KycVerification,
        }),
        fiatCurrency: fiatCurrencyFromCode(data.userProfile.FiatCurrencyCode),
        fiatPaymentCurrency: fiatCurrencyFromCode(
          data.userProfile.FiatPaymentCurrencyCode || data.userProfile.FiatCurrencyCode,
        ),
        /**
          We decided to have FiatPaymentCurrencyCode as optional,
          that way we need to default to FiatCurrencyCode
        */
        FiatPaymentCurrencyCode:
          data.userProfile.FiatPaymentCurrencyCode || data.userProfile.FiatCurrencyCode,
        fullDisplayName: getUserFullDisplayName(data.userProfile.PersonalName),
        isNoahUser: data.userProfile.Groups?.includes(UserGroup.Noah),
        lnAddress: getUserLnAddress(data.userProfile.UsernameDisplay),
        userInitial: getUserInitial(
          data.userProfile.PersonalName?.FirstName,
          data.userProfile.UsernameDisplay,
        ),
      } satisfies TpUserProfileUi,
      userSettingsConsent: data.userSettingsConsent[0],
    }),
  });

  const queryClient = useQueryClient();
  const invalidate = useCallback(async () => {
    await queryClient.invalidateQueries(userInitQueryKey);
  }, [queryClient]);

  return {
    ...userInitResponse,
    invalidate,
  };
}
