import React from 'react';
import type { LocationDescriptor } from 'history';

type TpRoutingFn = <T = unknown>(to: string | LocationDescriptor<T>) => void;
type TpParams = Record<string, string | string[]>;
type TpParamsPartial = Record<string, string | string[] | undefined>;

export type CtxRouter = {
  goBack: () => void;
  goForward: () => void;
  pathname: string;
  push: TpRoutingFn;
  replace: TpRoutingFn;
  search: URLSearchParams;
  useOnRouteChange: (onRouteChange: () => void) => void;
  useParams: {
    <T extends TpParams = TpParams>(): T;
    <T extends TpParamsPartial = TpParamsPartial>(): T;
  };
};

// Create the context with a default undefined value
export const RouterContext = React.createContext<CtxRouter | undefined>(undefined);
